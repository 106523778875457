/*  
  below styles is a hack to solve charts with responsive container cannot be shown when print
  the issue still not 100% solved, the charts is a bit small
  https://github.com/recharts/recharts/issues/1114
*/
@media print {
  /* Recalculate width in print mode to fix the bug the graph isn't responsive when print. */
  .recharts-wrapper, .recharts-surface, .recharts-legend-wrapper {
      width: 100%!important;
  }
  /* 
    Prevent recharts-legend-item have space between <svg> icon and <span> due to recalculate width: 100%
    try width: fit-content not work on safari 
  */
  .recharts-legend-item > svg{
      width: auto!important;
      display: inline-block!important;
      right: inherit!important; /* align to left */
  }
}